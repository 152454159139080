.mainProfileBox {
  height: 45px;
  width: 45px;
  background: #fff;
  position: relative;
  border-radius: 100px;
}

.mainProfileBox .profileBgDots {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 25px);
  z-index: -1;
}

.mainProfileBox .userProfile {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  border-width: 2px;
  border-style: solid;
}

.mainProfileBox .sheild {
  position: absolute;
  right: -5px;
  top: -5px;
}

/* .mainProfileBox .sheild svg path {
  fill: #1722bc !important;
  stroke: #50effd !important;
} */

.mainProfileBox .sheild span {
  display: none;
  color: #000;
  font-size: 5px;
  background: #fff !important;
  font-family: sans-serif;
  border-radius: 100px;
  padding: 1px 2px;
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.tenMember {
  border: 2px solid #ae8a27;
}

.tenMember .sheild span {
  display: block;
}

.whiteShield .userProfile {
  border-color: #fff;
}

.whiteShield .sheild svg path {
  fill: #fff;
  stroke: #a7bed3;
}

.whiteShield.offline .sheild svg path {
  fill: #ffffffb3;
}

.redShield .userProfile {
  border-color: #e20654;
}

.redShield .sheild svg path {
  fill: #e20654;
  stroke: #ee01e5;
}

.redShield.offline .sheild svg path {
  fill: #e50554b3;
}

.yellowShield .userProfile {
  border-color: #fcf202;
}

.yellowShield .sheild svg path {
  fill: #fcf202;
  stroke: #e9d362;
}

.yellowShield.offline .sheild svg path {
  fill: #fcf202b3;
}

.greenShield .userProfile {
  border-color: #33aa5e;
}

.greenShield .sheild svg path {
  fill: #33aa5e;
  stroke: #40d319;
}

.greenShield.offline .sheild svg path {
  fill: #34ad5fb3;
}

.blueShield .userProfile {
  border-color: #1722bc;
}

.blueShield .sheild svg path {
  fill: #212ee7;
  stroke: #50effd;
}

.blueShield.offline .sheild svg path {
  fill: #212ee7b3;
}
